import { Seo } from "@/components/seo";
import { withAuthGuard } from "@/hocs/with-auth-guard";
import { useAuth } from "@/hooks/use-auth";
import { SideNav } from "@/layouts/dashboard/layout/vertical-layout/side-nav";
import { paths } from "@/paths";
import { useSessionStorageItem } from "@/utils/route";
import { Box, Stack, Typography } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
import type { TeamConfigId, WorksheetId } from "allgood-schema";
import { capitalCase } from "change-case";
import { type FC, type ReactNode, useEffect } from "react";
import { useParams } from "react-router-dom";
import { trpc } from "src/utils/trpc";
import { StreamHandlersProvider } from "../task/context/chat-stream-context";

interface LayoutProps {
  children?: ReactNode;
  sidebar?: boolean;
}

export const Layout: FC<LayoutProps> = withAuthGuard(
  ({ sidebar = true, children }) => {
    const auth = useAuth();
    const params = useParams();

    const [worksheetId, setWorksheetId] = useSessionStorageItem("worksheetId");

    const { data: worksheet } = trpc.worksheet.getById.useQuery({
      id: worksheetId as WorksheetId,
    });

    const { data: setupWorksheetTeamConfig } = trpc.agentWorkflow.get.useQuery(
      {
        id:
          worksheet?.config.setupWorksheetTeamConfigId ?? ("" as TeamConfigId),
      },
      {
        enabled: Boolean(worksheet?.config.setupWorksheetTeamConfigId),
      },
    );

    const { data: uploadProcessTeamConfig } = trpc.agentWorkflow.get.useQuery(
      {
        id: worksheet?.config.uploadProcessTeamConfigId ?? ("" as TeamConfigId),
      },
      {
        enabled: Boolean(worksheet?.config.uploadProcessTeamConfigId),
      },
    );

    useEffect(() => {
      if (params.worksheetId) {
        setWorksheetId(params.worksheetId);
      }
    }, [params]);

    return (
      <StreamHandlersProvider>
        <GlobalStyles
          styles={{
            body: {
              "--SideNav-width": sidebar ? "240px" : "0px",
            },
          }}
        />
        <Seo title="Worksheet" />
        {sidebar ? (
          <SideNav
            color="blend-in"
            items={[
              {
                key: "worksheet",
                items: [
                  {
                    key: "chatSetup",
                    title: setupWorksheetTeamConfig?.name ?? "Setup with Mary",
                    href: paths.worksheets.chatSetup,
                    icon: "chat",
                    matcher: {
                      type: "equals",
                      href: paths.worksheets.chatSetup,
                    },
                  },
                  {
                    key: "chatProcess",
                    title:
                      uploadProcessTeamConfig?.name ?? "Import List with Mary",
                    href: paths.worksheets.chatProcess,
                    icon: "chat",
                    matcher: {
                      type: "equals",
                      href: paths.worksheets.chatProcess,
                    },
                  },
                  {
                    key: "items",
                    title: capitalCase(worksheet?.config.nounPlural ?? "Items"),
                    href: paths.worksheets.items,
                    icon: "items",
                    matcher: {
                      type: "startsWith",
                      href: paths.worksheets.items,
                    },
                  },
                ],
              },
              {
                key: "config",
                title: "Configuration",
                items: [
                  {
                    key: "flow",
                    title: "Edit Flow",
                    href: paths.worksheets.flow,
                    icon: "flow",
                    matcher: {
                      type: "equals",
                      href: paths.worksheets.flow,
                    },
                  },
                  {
                    key: "settings",
                    title: "Settings",
                    href: paths.worksheets.settings,
                    icon: "sliders",
                    matcher: {
                      type: "startsWith",
                      href: paths.worksheets.settings,
                    },
                  },
                ],
              },
            ]}
            auth={auth}
            topChildren={
              <Box sx={{ m: 2, mb: 0 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: "center",
                    border: "1px solid #DDD",
                    borderRadius: "12px",
                    p: "4px 8px",
                  }}
                >
                  <Box sx={{ flex: "1 1 auto" }}>
                    <Typography variant="caption">Worksheet</Typography>
                    <Typography variant="subtitle2">
                      {worksheet?.name ?? ""}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            }
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            pl: "var(--SideNav-width)",
          }}
        >
          {children}
        </Box>
      </StreamHandlersProvider>
    );
  },
);
