import React, { lazy } from "react";
import type { RouteObject } from "react-router";
import { redirect } from "react-router-dom";
import { paths } from "src/paths";
import { buildRoute } from "@/utils/route";

const WorksheetFlowPage = lazy(() => import("src/pages/worksheet/flow-page"));
const WorksheetSettingsPage = lazy(
  () => import("src/pages/worksheet/settings-page"),
);
const WorksheetChatSetupPage = lazy(
  () => import("src/pages/worksheet/worksheet-chat-setup-page"),
);
const WorksheetChatProcessPage = lazy(
  () => import("src/pages/worksheet/worksheet-chat-process-page"),
);
const WorksheetItemsPage = lazy(() => import("src/pages/worksheet/items-page"));

export const worksheetRoutes: RouteObject[] = [
  {
    path: paths.worksheets.worksheet,
    loader: ({ params }) => {
      if (!params.worksheetId) {
        throw new Error("Can't load worksheet without worksheetId");
      }
      return redirect(
        buildRoute(paths.worksheets.items, { worksheetId: params.worksheetId }),
      );
    },
  },
  { path: paths.worksheets.chatSetup, element: <WorksheetChatSetupPage /> },
  { path: paths.worksheets.chatProcess, element: <WorksheetChatProcessPage /> },
  { path: paths.worksheets.flow, element: <WorksheetFlowPage /> },
  { path: paths.worksheets.settings, element: <WorksheetSettingsPage /> },
  { path: paths.worksheets.items, element: <WorksheetItemsPage /> },
];
