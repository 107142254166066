import { useAuth } from "@/hooks/use-auth";
import { lazy } from "react";
import type { RouterOutputs } from "src/utils/trpc";

const Error401Page = lazy(() => import("../pages/401"));

export enum Issuer {
  JWT = "JWT",
}

export const checkIsAllgoodUser = (user?: RouterOutputs["auth"]["me"]) =>
  user?.email.endsWith("@allgoodhq.com");

export const checkIsSuperUser = (user?: RouterOutputs["auth"]["me"]) =>
  user?.userType === "SUPERUSER";

export const checkIsAdmin = (user?: RouterOutputs["auth"]["me"]) =>
  checkIsSuperUser(user) || user?.userRole === "ADMIN";

export const SuperUserRoute = ({ element }: { element: JSX.Element }) => {
  const { user } = useAuth();

  if (!checkIsSuperUser(user)) {
    return <Error401Page />;
  }

  return element;
};

export const AdminRoute = ({ element }: { element: JSX.Element }) => {
  const { user } = useAuth();

  if (!checkIsAdmin(user)) {
    return <Error401Page />;
  }

  return element;
};
